import {Component} from '@angular/core';
import {AppPreferences, AuthData} from "../../../domain/models";
import {PreferencesService} from "../../../services/preferences/preferences.service";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
  authData: AuthData = new AuthData();

  constructor(
    private preferences: PreferencesService,
    public router: Router,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Авторизация");
  }

  saveAuthData() {
    let prefs = new AppPreferences();
    prefs.authData = this.authData
    this.preferences.setAppPreferences(prefs)
    this.router.navigateByUrl("")
  }
}
