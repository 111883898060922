<div>
  <div style="margin: 50px;" class="anim-rotate btn-mktg" [hidden]="isLoading == true">
    <i class="fa-solid fa-spinner fa-2xl"></i>
  </div>
  <table class="table" [hidden]="isLoading == false">
    <thead>
    <tr>
      <th>#</th>
      <th (click)="sortByAppVersion()">App version</th>
      <th>Participant ID</th>
      <th>Group ID</th>
      <th>Team ID</th>
      <th (click)="sortByBib()" scope="col" [appSort]="data" data-order="desc" data-name="bib">BIB</th>
      <th>Name</th>
      <th (click)="sortBySurname()">Surname</th>
      <th>Device {{registeredCount}}/{{totalCount}}</th>
      <th>SDK INT</th>
      <th>INCREM</th>
      <th>imaginaryId</th>
      <th (click)="sortByLastActiveTime()" scope="col" [appSort]="data" data-order="desc"
          data-name="lastActiveTime">
        lastActiveTime
      </th>
      <th>Start time</th>
      <th>Finish time</th>
      <th>Group</th>
      <th>Team</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let state of data">
      <td scope="row">{{data.indexOf(state) + 1}}</td>
      <td [ngClass]="{'td-highlighted': needHighlightAppVersion(state)}">{{state?.device?.deviceInfo?.appVersion}}</td>
      <td (click)="openParticipantDetails(state)"> {{ state?.participant?.id }}</td>
      <td (click)="openGroupDetails(state)">{{ state?.group?.id }}</td>
      <td (click)="openTeamDetails(state)">{{ state?.team?.id }}</td>
      <td>
        <a target="_blank" *ngIf="state?.participant!=null" class="button-link"
           [queryParams]="{ eventId: eventId, dayId: dayId, oldTeamId: state.team.id, cpId: state?.participant?.distances[0]?.controlPointSettingsId}"
           routerLink="/participants/{{state?.participant?.id}}">{{ state.participant?.bib }}</a>
      </td>
      <td>{{ state?.participant?.name }}</td>
      <td>{{ state?.participant?.surname }}</td>
      <td>
        <a target="_blank" *ngIf="state?.device!=null" class="button-link"
           routerLink="/devices/{{state?.device?.id}};cpId={{state?.participant?.distances[0]?.controlPointSettingsId}}">{{state?.device?.id}}</a>
        <!--           (click)="navigateToDetails(state?.device?.id)"-->
      </td>
      <td>{{state?.device?.deviceInfo?.systemSdkInt}}</td>
      <td>{{state?.device?.deviceInfo?.manufacturer}}</td>
      <td>{{state?.device?.imaginaryId}}</td>
      <td>{{getTime(state?.device?.lastActiveTime)}}</td>
      <td>{{getTime(state?.group?.controlStartTime)}}</td>
      <td>{{getTime(state?.group?.controlFinishTime)}}</td>
      <td>
        <a target="_blank" *ngIf="state?.group!=null" class="button-link"
           routerLink="/groups/{{state?.group?.id}}">
          {{ state?.group?.name }}
        </a>
      </td>
      <td>
        <a target="_blank" *ngIf="state?.group!=null" class="button-link"
           [queryParams]="{ eventId: eventId, dayId: dayId }"
           routerLink="/teams/{{state?.team?.id}}">
          {{ state?.team?.name }}
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
