import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ControlPointsSettings,
  EventDay,
  Format,
  GpsSettings,
  NewParticipant,
  ParticipantWithDeviceDto,
  PunchAlgorithm,
  UploadCSV
} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-day-details',
  templateUrl: './day-details.component.html',
  styleUrls: ['./day-details.component.scss']
})
export class DayDetailsComponent implements OnInit {

  model: EventDay;
  newModel: Format = new Format();
  newParticipant: NewParticipant = new NewParticipant();
  event: EventDay;
  formats: Format[];
  isUpdateEventEnabled = false;
  isCreateFormatEnabled = false;
  eventId: string = "";
  dayId: string = "";
  punchAlgorithms: PunchAlgorithm[];
  gpsSettings: GpsSettings[];
  closeResult = '';
  selectedAlgorithm: PunchAlgorithm = new PunchAlgorithm();
  selectedGpsSettings: GpsSettings = new GpsSettings();
  selectedControlPointsSettings: ControlPointsSettings = new ControlPointsSettings();
  controlPointsSettings: ControlPointsSettings[];

  participantToAdd: ParticipantWithDeviceDto = new ParticipantWithDeviceDto();
  uploadCsvModel: UploadCSV = new UploadCSV();

  participantsToAdd: NewParticipant[] = [];

  constructor(
    private fractalService: FractalService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private titleService: Title
  ) {
    titleService.setTitle("Настройка этапа")
  }

  ngOnInit() {
    const id1: string = this.route.snapshot.params.id1;
    const id2: string = this.route.snapshot.params.id2;
    this.eventId = id1;
    this.dayId = id2;
    this.getEventDay(this.eventId, this.dayId);
    this.getFormats(this.eventId, this.dayId);
    this.getPunchAlgorithms();
    this.getGpsSettings();
    this.fractalService.getControlPointsSettings().subscribe(g => {
      this.controlPointsSettings = g.sort((a, b) => {
        if (b.updatedAt == null) return -1;
        if (a.updatedAt < b.updatedAt) return 1;
        if (a.updatedAt > b.updatedAt) return -1;
        else return 0
      });
    })
  }

  getEventDay(eventId: string, dayId: string) {
    this.fractalService.getEventDay(eventId, dayId)
      .subscribe(day => {
          this.model = day;
          this.event = day;
          this.titleService.setTitle(day.name)
        }
      );
  }

  getFormats(eventId: string, dayId: string) {
    this.fractalService.getFormats(eventId, dayId)
      .subscribe(heroes => {
          this.formats = heroes
        }
      );
  }

  getPunchAlgorithms() {
    this.fractalService.getPunchAlgorithms().subscribe(p => {
      this.punchAlgorithms = p;
    })
  }

  getGpsSettings() {
    this.fractalService.getGpsSettings().subscribe(g => {
      this.gpsSettings = g;
    })
  }

  save() {
    this.fractalService.updateEventDay(this.eventId, this.model).subscribe(res => {
      this.model = res;
      this.notifyService.showSuccess("Обновили");
    });
  }

  uploadCsv(uploadCsv: UploadCSV) {
    this.notifyService.showInfo("Загружаем...");
    this.fractalService.uploadCsv(this.eventId, this.dayId, uploadCsv).subscribe(res => {
      this.uploadCsvModel = new UploadCSV();
      this.notifyService.showSuccess("Загрузили!");
    })
  }

  showOrHideUpdateDialog() {
    this.isUpdateEventEnabled = !this.isUpdateEventEnabled
  }

  update(format: Format) {
    console.log(format);
    this.fractalService.updateFormat(this.eventId, this.dayId, format);
  }

  open(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.save();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openAddParticipantDialog(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.uploadCsvModel.controlPointsSettingsId = this.selectedControlPointsSettings.id
      this.uploadCsvModel.gpsSettingsId = this.selectedGpsSettings.id
      this.uploadCsvModel.punchAlgorithmId = this.selectedAlgorithm.id
      this.uploadCsv(this.uploadCsvModel);
      console.log(this.model);
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openAddParticipantsDialog(content) {
    var tab = "\t"
    var crlf = "\n"
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.uploadCsvModel.controlPointsSettingsId = this.selectedControlPointsSettings.id
      this.uploadCsvModel.gpsSettingsId = this.selectedGpsSettings.id
      this.uploadCsvModel.punchAlgorithmId = this.selectedAlgorithm.id
      this.uploadCsvModel.data = ""
      this.participantsToAdd.forEach(x => {
        this.uploadCsvModel.data += x.bib + tab + x.group + tab + x.surname + tab + x.name + tab + x.teamName + tab + x.birthDate + tab + tab + tab + tab + tab + tab + tab + x.childGroup + crlf
      })
      this.uploadCsvModel.data = this.uploadCsvModel.data.substring(0, this.uploadCsvModel.data.length - 1)
      console.log(this.uploadCsvModel)
      this.uploadCsv(this.uploadCsvModel);
      console.log(this.model);
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    console.log(reason);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openCreateDialog(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.newFormat();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  newFormat() {
    const dayId: string = this.route.snapshot.params.id2;
    const id: string = this.route.snapshot.params.id1;
    this.newModel.punchAlgorithm = this.selectedAlgorithm;
    this.newModel.gpsSettings = this.selectedGpsSettings;
    this.fractalService.createFormat(id, dayId, this.newModel).subscribe(() => {
        this.notifyService.showSuccess("Создали");
        this.getFormats(this.eventId, this.dayId);
      }
    );
  }

  handleAdd() {
    if (this.newParticipant.bib === "") {
      this.notifyService.showError("Заполните номер участника")
      return;
    }
    if (this.newParticipant.group === "") {
      this.notifyService.showError("Заполните группу для участия")
      return;
    }
    if (this.newParticipant.surname === "") {
      this.notifyService.showError("Заполните фамилию участника")
      return;
    }
    if (this.newParticipant.name === "") {
      this.notifyService.showError("Заполните имя участника")
      return;
    }
    if (this.newParticipant.teamName === "") {
      this.notifyService.showError("Заполните название команды")
      return;
    }
    if (this.newParticipant.birthDate === "") {
      this.notifyService.showError("Заполните год/дату рождения")
      return;
    }
    this.participantsToAdd.push(this.newParticipant)
    console.log(this.newParticipant)
    this.newParticipant = new NewParticipant();
  }

  handleDelete(p: NewParticipant) {
    this.participantsToAdd = this.participantsToAdd.filter(obj => {
      return obj !== p
    });
  }
}
