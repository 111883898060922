<div class="content">
  <div style="display: inline;">
    <h5 class="title-base-left">Bib</h5>
    <input type="text" class="form-control" [(ngModel)]="participant.bib" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h5 class="title-base-left">Имя</h5>
    <input type="text" class="form-control" [(ngModel)]="participant.name" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h5 class="title-base-left">Фамилия</h5>
    <input type="text" class="form-control" [(ngModel)]="participant.surname" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h5 class="title-base-left">Время старта</h5>
    <input type="text" class="form-control" [(ngModel)]="participant.controlStartTime" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h5 class="title-base-left">Время финиша</h5>
    <input type="text" class="form-control" [(ngModel)]="participant.controlFinishTime" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h5 class="title-base-left">Год рождения</h5>
    <input type="text" class="form-control" [(ngModel)]="participant.birthDate" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="display: inline;">
    <h5 class="title-base-left">Телефон</h5>
    <input type="text" class="form-control" [(ngModel)]="participant.phone" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div>
    <h5 class="title-base-left">Статус</h5>
    <select class="form-control" id="status" [(ngModel)]="participant.status"
            [value]="participant.status" name="punch-algorithm" ng-model="format.punchAlgorithm">
      <option value="normal">Норм</option>
      <option value="not_started">Не стартовал</option>
      <option value="removed">Сход</option>
      <option value="exceeding_control_time">Превышение КВ</option>
      <option value="dsq">Дисквалификация</option>
    </select>
  </div>

  <div style="display: inline;">
    <h5 class="title-base-left">Комментарий</h5>
    <input type="text" class="form-control" [(ngModel)]="participant.comment" placeholder=""
           name="oldTeamId" #name="ngModel">
  </div>

  <div style="margin: 20px;">
    <button type="button" class="btn btn-primary" (click)="save()">Сохранить</button>
  </div>

  <div style="margin: 20px;">
<!--    <button type="button" class="btn btn-primary" routerLink="events/:id1/days/:id2/formats/:id3/groups/:id4/teams/:id5/participants/{{participantId}}">Устройства</button>-->
    <button type="button" class="btn btn-primary" (click)="registerNewDevice()">Зарегистрировать устройство</button>
  </div>

  <h1 class="title-base-left">Устройства</h1>
  <!-- <div class="heroes">
    <li *ngFor="let device of devices">
      <a routerLink="/devices/{{device.id}}">
        <span>id: {{device.id}}</span>
        <div>
          <p>imaginaryId: {{device.imaginaryId}}</p>
          <p>manufacturer: {{device.deviceInfo.manufacturer}}</p>
          <p>model: {{device.deviceInfo.model}}</p>
          <p>systemVersion: {{device.deviceInfo.systemVersion}}</p>
          <p>appVersion: {{device.deviceInfo.appVersion}}</p>
          <p>createdDate: {{device.createdDate}}</p>
          <p>lastActiveTime: {{device.lastActiveTime}}</p>
          <button routerLink="devices/{{device.id}}/punches">Punches</button>
          <button routerLink="devices/{{device.id}}/state">Battery state</button>
        </div>
      </a>
  </div> -->
  <div class="card">
    <table class="table">
      <thead>
        <tr>
          <th>id</th>
          <th>imaginaryId</th>
          <th>manufacturer</th>
          <th>model</th>
          <th>systemVersion</th>
          <th>appVersion</th>
          <th>createdDate</th>
          <th>lastActiveTime</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let obj of devices" class="div-center">
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.id}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.imaginaryId}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.deviceInfo.manufacturer}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.deviceInfo.model}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.deviceInfo.systemVersion}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.deviceInfo.appVersion}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.createdDate}}</p>
            </div>
          </td>
          <td>
            <div class="device-details">
              <p class="p-base">{{obj.lastActiveTime}}</p>
            </div>
          </td>
          <td>
            <div>
              <a class="button-black" routerLink="/devices/{{obj?.id}};cpId={{cpId}}" target="_blank">Отметки</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card">
    <div class="form-group">
      <p for="search-text">Поиск команды</p>
      <input type="email" class="form-control" id="search-text" aria-describedby="search-text"
             [(ngModel)]="searchText" placeholder="Enter text to search"
             autofocus>
    </div>
    <ul class="list-group list-group-flush">
      <li class="item-multiple-buttons" *ngFor="let team of teams?.teams | filterList: searchText">
        <div>
          <p>{{team?.id}} | {{team?.bib}} | {{team?.name}}</p>
          <div class="close">
            <span class="close-item" (click)="updateTeam(team)"><i class="fa fa-link"></i></span>
<!--            <span class="close-item"><i class="fa fa-unlink"></i></span>-->
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
