<script type="text/javascript">
  function showHideRow(row) {
    $("#" + row).toggle();
  }
</script>
<div>
  <table class="table">
    <thead>
    <tr>
      <th class="tr-base">CP</th>
      <th class="tr-base">All punches count ({{data?.total}})</th>
      <th class="tr-base">Admin added count ({{data?.totalAddedAdmin}})</th>
      <th class="tr-base">Gps added count ({{data?.totalAddedGps}})</th>
      <th class="tr-base">QR added count ({{data?.totalAddedQr}})</th>
      <th class="tr-base">First participant</th>
      <th class="tr-base">First timestamp(utc)</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let state of data?.controlPoint">
      <tr class="tr-base" (click)="state.expanded = !state.expanded">
        <td class="tr-base">{{ state?.name }}</td>
        <td class="tr-base">{{ state?.allPunches?.length }}</td>
        <td class="tr-base">{{ state?.adminAdded?.length }}</td>
        <td class="tr-base">{{ state?.gpsAdded?.length }}</td>
        <td class="tr-base">{{ state?.qrAdded?.length }}</td>
        <td class="tr-base">{{ state?.participants[0]?.bib}}
          - {{ state?.participants[0]?.name}} {{ state?.participants[0]?.surname}}</td>
        <td class="tr-base">{{ state?.participants[0]?.newDevice?.punches[0]?.timestamp }}</td>
      </tr>
      <ng-container *ngIf="state.expanded">
        <tr class="tr-base" *ngFor="let p of state?.participants">
          <td class="tr-expand">{{state?.participants?.indexOf(p) + 1}}</td>
          <td class="tr-expand"></td>
          <td class="tr-expand"></td>
          <td class="tr-expand"></td>
          <td class="tr-expand">{{ p?.bib}} - {{ p?.name}} {{ p?.surname}} - {{ p?.id}}</td>
          <td class="tr-expand">{{p?.newDevice?.punches[0]?.timestamp}}</td>
          <td class="tr-expand">{{getTimeFromPreviousParticipantForPoint(state.participants, p, state?.minor)}}</td>
        </tr>
      </ng-container>
    </ng-container>
    </tbody>
  </table>
</div>
