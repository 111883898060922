import {Component, OnInit} from '@angular/core';
import {AnalyzedPunchData, Device, EventDay, Participant, ParticipantWithDeviceDto} from 'src/app/domain/models';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewportScroller} from '@angular/common';
import {Title} from "@angular/platform-browser";
import {FractalService} from "../../services/fractal/fractal.service";
import {analyzePunches, getSortedGroups, parseResults, sortResults, sortTeams2} from "../../domain/data-utls";
import {EventResultsTableComponent} from '../event-results-table/event-results-table.component';
import {getDiffBetweenTimes, timeToMillis} from "../../domain/date-utils";

@Component({
  selector: 'app-analyze-points',
  templateUrl: './analyze-points.component.html',
  styleUrls: ['./analyze-points.component.scss']
})
export class AnalyzePointsComponent implements OnInit {

  data: AnalyzedPunchData;
  sortedGroups: string[];
  currentEvent: EventDay;
  isLoading: boolean = true;
  total: number = 0;

  constructor(
    private actRoute: ActivatedRoute,
    private api: FractalService,
    private modalService: NgbModal,
    private scroller: ViewportScroller,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Анализ КП");
  }

  ngOnInit(): void {
    this.api.getResultsNew(this.actRoute.snapshot.params.id1, this.actRoute.snapshot.params.id2).subscribe((res) => {
      EventResultsTableComponent.cpSettings = res.controlPointsSettings
      let newResult = parseResults(res)
      newResult = sortResults(newResult)
      this.sortedGroups = getSortedGroups(newResult)
      newResult = sortTeams2(newResult, "rogaine")

      this.data = analyzePunches(newResult.participants); //TODO convert list to analyzed points
      this.isLoading = false
    })
    this.api.GetDay(this.actRoute.snapshot.params.id, this.actRoute.snapshot.params.id2).subscribe((res) => {
      this.currentEvent = res;
    })
  }

  getTimeFromPreviousParticipantForPoint(
    participants: ParticipantWithDeviceDto[],
    currentParticipant: ParticipantWithDeviceDto,
    pointCode: String,
  ) {
    let currentParticipantIndex = participants?.indexOf(currentParticipant)
    if (currentParticipantIndex == 0) return "-"
    let previousParticipant = participants[currentParticipantIndex - 1]
    let foundPunchOfCurrentParticipant = currentParticipant.newDevice.punches.find(x => x.code.toString() == pointCode)
    let foundPunchOfPreviousParticipant = previousParticipant.newDevice.punches.find(x => x.code.toString() == pointCode)
    return getDiffBetweenTimes(foundPunchOfPreviousParticipant.timestamp, foundPunchOfCurrentParticipant.timestamp)
  }

  getLastActiveDevice(participant: Participant) {
    participant.devices.sort((a: Device, b: Device) => {
      let aTime = timeToMillis(a.lastActiveTime)
      let bTime = timeToMillis(b.lastActiveTime)
      if (aTime > bTime) return 1
      if (aTime < bTime) return -1
      return 0;
    })
  }
}
