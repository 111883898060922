<div class="container-events">
    <button class="button-main" (click)="open(content)">
        Создать мероприятие
    </button>
    <h1 class="title-base">Список мероприятий</h1>
    <div class="div-center list">
        <li *ngFor="let event of events">
          <a routerLink="/events/{{event.id}}">
            <span class="badge">{{event.name}}</span>
<!--            {{event.description}}-->
          </a>
      </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h3 class="p-black" id="modal-basic-title">
      Создание мероприятия
    </h3>
    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">
                ×
            </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <label for="name">Название</label>
      <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name"
             placeholder="Яркая весна" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
      <div class="form-group">
        <label for="description">Описание</label>
        <input type="text" class="form-control" id="description" [(ngModel)]="model.description"
               placeholder="Мероприятие для самых активных" name="description">
      </div>

      <div class="form-group">
        <label for="beginDate">Дата начала</label>
        <input type="text" class="form-control" id="beginDate" [(ngModel)]="model.beginDate" name="beginDate"
               placeholder="2021-07-10T14:02:33Z" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <div class="form-group">
        <label for="endDate">Дата окончания</label>
        <input type="text" class="form-control" id="endDate" [(ngModel)]="model.endDate" name="endDate"
               placeholder="2021-07-10T14:02:33Z">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <div class="form-group" style="display: block;">
        <label for="registrationBeginDate">Дата начала регистрации</label>
        <input type="text" class="form-control" id="registrationBeginDate" placeholder="2021-07-10T14:02:33Z"
               [(ngModel)]="model.registrationBeginDate" name="registrationBeginDate">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="published" [(ngModel)]="model.published"
                 name="published">
          <label class="form-check-label" for="published">Мероприятие опубликовано</label>
        </div>
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="closed" [(ngModel)]="model.closed"
                 name="closed">
          <label class="form-check-label" for="closed">Мероприятие закрыто</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
