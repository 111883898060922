import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DevicesStateComponent} from './pages/devices-state/devices-state.component';
import {FormatDetailsComponent} from './pages/format-details/format-details.component';
import {GroupDetailsComponent} from './pages/group-details/group-details.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {ParticipantDetailsComponent} from './pages/participant-details/participant-details.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {TeamDetailsComponent} from './pages/team-details/team-details.component';
import {DayDetailsComponent} from './pages/day-details/day-details.component';
import {DevicesManageComponent} from './pages/devices-manage/devices-manage.component';
import {EventDaysComponent} from './pages/event-days/event-days.component';
import {EventsComponent} from './pages/events/events.component';
import {ManageLinksComponent} from './pages/manage-links/manage-links.component';
import {ParticipantsManageComponent} from './pages/participants-manage/participants-manage.component';
import {EventResultsTableComponent} from "./pages/event-results-table/event-results-table.component";
import {DeviceInfoComponent} from "./pages/device-info/device-info.component";
import {GroupInfoComponent} from "./pages/group-info/group-info.component";
import {TeamInfoComponent} from "./pages/team-info/team-info.component";
import {ParticipantInfoComponent} from "./pages/participant-info/participant-info.component";
import {AnalyzePointsComponent} from './pages/analyze-points/analyze-points.component';
import {EditControlPointsComponent} from "./pages/edit-control-points/edit-control-points.component";
import {EventInfoComponent} from "./pages/event-info/event-info.component";
import {AnalyzeGpsTracksComponent} from "./pages/analyze-gps-tracks/analyze-gps-tracks.component";
import {SignInComponent} from "./pages/auth/sign-in/sign-in.component";
import {EventsManageComponent} from "./pages/events-manage/events-manage.component";
import {ControlPointsSettingsComponent} from "./pages/control-points-settings/control-points-settings.component";
import {GpsSettingsComponent} from "./pages/gps-settings/gps-settings.component";
import {PunchAlgorithmComponent} from "./pages/punch-algorithm/punch-algorithm.component";

const routes: Routes = [
  {path: '', redirectTo: '/events', pathMatch: 'full'},
  {path: 'events', component: EventsComponent},
  {path: 'events-new', component: EventsManageComponent},
  {path: 'devices/:id', component: DeviceInfoComponent},
  {path: 'groups/:id', component: GroupInfoComponent},
  {path: 'teams/:id', component: TeamInfoComponent},
  {path: 'participants/:id', component: ParticipantInfoComponent},
  {path: 'events/:id', component: EventDaysComponent},
  {path: 'events/:id1/days/:id2', component: DayDetailsComponent},
  {path: 'events/:id1/days/:id2/results', component: EventResultsTableComponent},
  {path: 'events/:id1/days/:id2/analyze', component: AnalyzePointsComponent},
  {path: 'events/:id1/days/:id2/analyze-gps', component: AnalyzeGpsTracksComponent},
  {path: 'events/:id1/days/:id2/state', component: DevicesStateComponent},
  {path: 'events/:id1/days/:id2/info', component: EventInfoComponent},
  {path: 'events/:id1/days/:id2/devices-manage', component: DevicesManageComponent},
  {path: 'events/:id1/days/:id2/manage-all', component: ParticipantsManageComponent},
  {path: 'events/:id1/days/:id2/manage-links', component: ManageLinksComponent},
  {path: 'events/:id1/days/:id2/formats/:id3', component: FormatDetailsComponent},
  {path: 'events/:id1/days/:id2/formats/:id3/groups/:id4', component: GroupDetailsComponent},
  {path: 'events/:id1/days/:id2/groups/:id4', component: GroupDetailsComponent},
  {path: 'events/:id1/days/:id2/formats/:id3/groups/:id4/teams/:id5', component: TeamDetailsComponent},
  {path: 'events/:id1/days/:id2/teams/:id5', component: TeamDetailsComponent},
  {
    path: 'events/:id1/days/:id2/formats/:id3/groups/:id4/teams/:id5/participants/:id6',
    component: ParticipantDetailsComponent
  },
  {path: 'events/:id1/days/:id2/participants/:id6', component: ParticipantDetailsComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'auth/sign-in', component: SignInComponent},
  {path: 'settings/control-points/:id', component: EditControlPointsComponent},
  {path: 'settings/control-points', component: ControlPointsSettingsComponent},
  {path: 'settings/gps', component: GpsSettingsComponent},
  {path: 'settings/punch-algorithms', component: PunchAlgorithmComponent},
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
