<div class="div-center">
  <div>
    <h1>Статистика по мероприятию</h1>
    <h5>Всего участников заявлено: {{data?.total}}</h5>
    <h5>Количество финишёров: {{data?.finished}}</h5>
    <h5>Количество не приехавших: {{data?.notStarted}}</h5>
    <h5>Количество основных: {{data?.main}}</h5>
    <h5>Количество льготников: {{data?.beneficiary}}</h5>
    <button class="button-main" (click)="copyAsCSV()">Загрузить список</button>
    <!--    <div>-->
    <!--      <h5>Дата создания: {{device?.createdDate}}</h5>-->
    <!--    </div>-->
    <!--    <h5>Мнимый идентификатор: {{device?.imaginaryId}}</h5>-->
    <!--    <h5>Последняя активность: {{device?.lastActiveTime}}</h5>-->
    <!--    <h5>Версия приложения: {{device?.deviceInfo?.appVersion}}</h5>-->
    <!--    <h5>Производитель: {{device?.deviceInfo?.manufacturer}}</h5>-->
    <!--    <h5>Модель: {{device?.deviceInfo?.model}}</h5>-->
    <!--    <h5>Версия Android: {{device?.deviceInfo?.androidVersion}}</h5>-->
    <!--    <button class="btn btn-success mr-1" (click)="open(content)">Добавить</button>-->
    <!--    <div style="margin: 20px;">-->
    <!--      <button class="btn btn-success mr-1" (click)="open2(content2)">Добавить отметки (new)</button>-->
    <!--    </div>-->
    <!--    <input type="text" class="form-control" style="margin: 20px;" id="deviceIdForCopy" [(ngModel)]="deviceIdForCopy"-->
    <!--           name="deviceIdForCopy" placeholder="">-->
    <!--    <button class="btn btn-success mr-1" style="margin: 20px;" (click)="copy()">Скопировать на устройство</button>-->
    <!---->
    <!--    <input type="text" class="form-control" style="margin: 20px;" id="participantForLink"-->
    <!--           [(ngModel)]="participantForLink"-->
    <!--           name="participantForLink" placeholder="">-->
    <!--    <button class="btn btn-success mr-1" style="margin: 20px;" (click)="link()">Привязать к участнику</button>-->
    <!--    <button class="btn btn-success mr-1" style="margin: 20px;" (click)="downloadGpx()">Скачать gpx</button>-->
  </div>

<!--  <div>-->
<!--    &lt;!&ndash; <p id="{{p.groupName}}" class="table-header">{{p.groupName}}</p> &ndash;&gt;-->
<!--    <div>-->
<!--      <table class="table">-->
<!--        <thead>-->
<!--        <tr>-->
<!--          <th>#</th>-->
<!--          <th>uuid</th>-->
<!--          <th>major</th>-->
<!--          <th>minor</th>-->
<!--          <th>timestamp</th>-->
<!--          <th>isDuplicate</th>-->
<!--          <th>actions</th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--&lt;!&ndash;        <tr *ngFor="let p of device?.punchData?.punches" class="div-center">&ndash;&gt;-->
<!--&lt;!&ndash;          <td>{{device?.punchData?.punches?.indexOf(p) + 1}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.uuid}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.major}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.minor}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.timestamp}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.isDuplicate}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <button (click)="delete(device,p)" class="btn btn-danger">Удалить</button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;        </tr>&ndash;&gt;-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </div>-->

<!--&lt;!&ndash;    <div>&ndash;&gt;-->
<!--&lt;!&ndash;      <table class="table">&ndash;&gt;-->
<!--&lt;!&ndash;        <thead>&ndash;&gt;-->
<!--&lt;!&ndash;        <tr>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>#</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>uuid</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>major</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>minor</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>timestamp</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>isDuplicate</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>actions</th>&ndash;&gt;-->
<!--&lt;!&ndash;        </tr>&ndash;&gt;-->
<!--&lt;!&ndash;        </thead>&ndash;&gt;-->
<!--&lt;!&ndash;        <tbody>&ndash;&gt;-->
<!--&lt;!&ndash;        <tr *ngFor="let p of device?.punchData?.deletedPunches" class="div-center">&ndash;&gt;-->
<!--&lt;!&ndash;          <td>{{device?.punchData?.deletedPunches?.indexOf(p) + 1}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.uuid}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.major}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.minor}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.timestamp}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <p class="p-base">{{p.isDuplicate}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="device-details">&ndash;&gt;-->
<!--&lt;!&ndash;              <button class="btn btn-success" (click)="restore(device,p)">Восстановить</button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;        </tr>&ndash;&gt;-->
<!--&lt;!&ndash;        </tbody>&ndash;&gt;-->
<!--&lt;!&ndash;      </table>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
</div>
