<div class="container">
  <h1 class="title-base">Настройка мероприятия</h1>
  <div>
    <button class="button-main" (click)="open(content)">Открыть диалог с обновлением данных</button>
  </div>
  <div>
    <button class="button-main" (click)="openCreateDialog(content2)">Создать этап</button>
  </div>
  <h1 class="title-base">Дни мероприятия</h1>
  <div class="div-center list">
    <li *ngFor="let day of days">
      <a routerLink="/events/{{event.id}}/days/{{day.id}}">
        <span class="badge">{{day.name}}</span>
        <span class="badge">{{day.description}}</span>
      </a>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Обновление мероприятия
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <label for="name">Название</label>
      <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name"
        placeholder="Яркая весна" #name="ngModel">
      <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
        Обязательное поле
      </div>
      <div class="form-group">
        <label for="description">Описание</label>
        <input type="text" class="form-control" id="description" [(ngModel)]="model.description"
          placeholder="Мероприятие для самых активных" name="description">
      </div>

      <div class="form-group">
        <label for="beginDate">Дата начала</label>
        <input type="text" class="form-control" id="beginDate" [(ngModel)]="model.beginDate" name="beginDate"
          placeholder="2021-07-10T14:02:33Z" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <div class="form-group">
        <label for="endDate">Дата окончания</label>
        <input type="text" class="form-control" id="endDate" [(ngModel)]="model.endDate" name="endDate"
          placeholder="2021-07-10T14:02:33Z">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <div class="form-group" style="display: block;">
        <label for="registrationBeginDate">Дата начала регистрации</label>
        <input type="text" class="form-control" id="registrationBeginDate" placeholder="2021-07-10T14:02:33Z"
          [(ngModel)]="model.registrationBeginDate" name="registrationBeginDate">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Обязательное поле
        </div>
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="published" [(ngModel)]="model.published" name="published">
          <label class="form-check-label" for="published">Мероприятие опубликовано</label>
        </div>
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="closed" [(ngModel)]="model.closed" name="closed">
          <label class="form-check-label" for="closed">Мероприятие закрыто</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">
      Создание этапа
    </h1>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <label for="name">Название</label>
      <input type="text" class="form-control" id="name" required [(ngModel)]="modelNew.name" name="name"
        placeholder="Яркая весна" #name="ngModel">
      <div class="form-group">
        <label for="description">Описание</label>
        <input type="text" class="form-control" id="description" [(ngModel)]="modelNew.description"
          placeholder="Мероприятие для самых активных" name="description">
      </div>

      <div class="form-group">
        <label for="beginDate">Дата начала</label>
        <input type="text" class="form-control" id="beginDate" [(ngModel)]="modelNew.beginDate" name="beginDate"
          placeholder="2021-07-10T14:02:33Z" #name="ngModel">
      </div>

      <div class="form-group">
        <label for="endDate">Дата окончания</label>
        <input type="text" class="form-control" id="endDate" [(ngModel)]="modelNew.endDate" name="endDate"
          placeholder="2021-07-10T14:02:33Z">
      </div>

      <div class="form-group">
        <label for="ogpsEventId">OGPS ID</label>
        <input type="text" class="form-control" id="ogpsEventId" [(ngModel)]="modelNew.ogpsEventId" name="ogpsId"
          placeholder="5555">
      </div>

      <div class="form-group">
        <label for="ogpsEventUrl">OGPS URL</label>
        <input type="text" class="form-control" id="ogpsEventUrl" [(ngModel)]="modelNew.ogpsEventUrl" name="ogpsEventUrl"
          placeholder="https://viewer.o-gps-center.ru/viewer/event/10377/">
      </div>

      <div class="form-group" style="display: block;">
        <label for="registrationBeginDate">Дата начала регистрации</label>
        <input type="text" class="form-control" id="registrationBeginDate" placeholder="2021-07-10T14:02:33Z"
          [(ngModel)]="modelNew.registrationBeginDate" name="registrationBeginDate">
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="published" [(ngModel)]="modelNew.published" name="published">
          <label class="form-check-label" for="published">Мероприятие опубликовано</label>
        </div>
      </div>

      <div class="form-group form-check">
        <div>
          <input type="checkbox" class="form-check-input" id="closed" [(ngModel)]="modelNew.closed" name="closed">
          <label class="form-check-label" for="closed">Мероприятие закрыто</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Save
    </button>
  </div>
</ng-template>
