import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ControlPointsSettings,
  Format,
  GpsSettings,
  Group,
  PunchAlgorithm,
  SelectionalDistance,
  Team
} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit {

  model: Group;
  event: Format;
  teams: Team[];
  isUpdateEventEnabled = false;
  isCreateFormatEnabled = false;
  eventId: string = "";
  dayId: string = "";
  formatId: string = "";
  groupId: string = "";
  teamId: string = "";
  selectedAlgorithm: PunchAlgorithm;
  selectedGpsSettings: GpsSettings;
  punchAlgorithms: PunchAlgorithm[];
  gpsSettings: GpsSettings[];
  newTeamModel = new Team();
  distance: SelectionalDistance = new SelectionalDistance();
  controlTime: number = 0;
  controlPointsSettings: ControlPointsSettings[];
  selectionalControlPointsSettings: ControlPointsSettings;
  closeResult = '';

  constructor(
    private fractalService: FractalService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private titleService: Title,
    private notifyService: NotificationService
  ) {
    titleService.setTitle("Настройки группы")
  }

  ngOnInit() {
    const id1: string = this.route.snapshot.params.id1;
    const id2: string = this.route.snapshot.params.id2;
    const id3: string = this.route.snapshot.params.id3;
    const id4: string = this.route.snapshot.params.id4;
    const id5: string = this.route.snapshot.params.id5;

    this.eventId = id1;
    this.dayId = id2;
    this.formatId = id3;
    this.groupId = id4;
    this.teamId = id5;

    this.getGroup(this.groupId);
    this.fractalService.getPunchAlgorithms().subscribe(p => {
      this.punchAlgorithms = p;
    })
    this.fractalService.getGpsSettings().subscribe(g => {
      this.gpsSettings = g;
    })
    this.fractalService.getTeams(this.eventId, this.dayId, this.formatId, this.groupId).subscribe(g => {
      this.teams = g;
    })
    this.fractalService.getControlPointsSettings().subscribe(g => {
      this.controlPointsSettings = g;
    })
  }

  getGroup(groupId: string) {
    this.fractalService.getGroupById(groupId)
      .subscribe(heroes => {
          this.model = heroes
        }
      );
  }

  save() {
    console.log("model: " + this.model);

    this.distance.controlTimeInSeconds = this.controlTime;
    this.distance.minCount = 0;
    this.distance.type = "selectional";
    // this.distance.controlPointSettingsId = this.selectionalControlPointsSettings.id;
    this.model.distances = new Array(this.distance);


    this.fractalService.updateGroup(this.eventId, this.dayId, this.formatId, this.groupId, this.model).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  showOrHideUpdateDialog() {
    this.isUpdateEventEnabled = !this.isUpdateEventEnabled
  }

  showOrHideCreateDialog() {
    this.isCreateFormatEnabled = !this.isCreateFormatEnabled
    this.fractalService.getTeams(this.eventId, this.dayId, this.formatId, this.groupId).subscribe(g => {
      this.teams = g;
    })
  }

  openCreateDialog(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log(this.punchAlgorithms);
      console.log(this.gpsSettings);
      this.newHero();
      console.log(this.model);
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    console.log(reason);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  newHero() {
    console.log(this.model);
    const dayId: string = this.route.snapshot.params.id2;
    const id: string = this.route.snapshot.params.id1;
    const formatId: string = this.route.snapshot.params.id3;
    const groupId: string = this.route.snapshot.params.id4;

    this.fractalService.createTeam(id, dayId, formatId, groupId, this.newTeamModel).subscribe(heroes => {
        this.notifyService.showSuccess(heroes);
        this.fractalService.getTeams(this.eventId, this.dayId, this.formatId, this.groupId).subscribe(g => {
          this.teams = g;
        })
      }
    );
  }
}
