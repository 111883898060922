import {Component, OnInit} from '@angular/core';
import {Device, Participant, Team, TeamsAndGroups, UpdateLinks} from "../../domain/models";
import {ActivatedRoute} from "@angular/router";
import {FractalService} from "../../services/fractal/fractal.service";
import {Title} from "@angular/platform-browser";
import {NotificationService} from "../../services/notifications/notification.service";

@Component({
  selector: 'app-participant-info',
  templateUrl: './participant-info.component.html',
  styleUrls: ['./participant-info.component.scss']
})
export class ParticipantInfoComponent implements OnInit {

  participant: Participant = new Participant();
  participantId: string;
  searchText = '';
  teams: TeamsAndGroups;
  eventId: string = '';
  dayId: string = '';
  teamId: string = '';
  devices: Device[];
  cpId: string = "";

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private titleService: Title,
    private notifyService: NotificationService
  ) {
    route.params.subscribe(params => {
        console.log(params)
        this.parseRouteParams(params.id?.toString())
      }
    )
    route.queryParams.subscribe(params => {
        this.cpId = params.cpId
      }
    )
    titleService.setTitle("Изменение участника")
  }

  parseQueryParams(params: string) {
    console.log(params)
    if (params == null) return
    var splitted = params?.split(";")
    this.cpId = splitted[1]?.split('=')[1]
  }

  parseRouteParams(params: string) {
    console.log(params)
    if (params == null) return
    var splitted = params?.split(";")
    this.participantId = splitted[0];
    this.cpId = splitted[1]?.split('=')[1]
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.eventId = params['eventId'] || '';
      this.dayId = params['dayId'] || '';
      this.teamId = params['oldTeamId'] || '';
      this.api.getTeamsAndGroups(this.eventId, this.dayId).subscribe(g => {
        this.teams = g;
      });
    });
    this.getDevices()
    this.api.getParticipantById(this.participantId).subscribe(res => {
      this.participant = res;
    });
  }

  updateTeam(team: Team) {
    this.notifyService.showInfo("Обновляем...");
    this.api.linkParticipantToOtherTeam(this.eventId, this.dayId, this.teamId, new UpdateLinks(this.participantId, team.id)).subscribe(res => {
      this.notifyService.showSuccess("Обновлено")
    });
  }

  save() {
    console.log(this.participant);
    this.api.updateParticipantById(this.participantId, this.participant).subscribe(res => {
        this.notifyService.showSuccess("Обновлено")
      }
    )
  }

  getDevices() {
    this.api.getDevices(this.participantId).subscribe(g => {
      this.devices = g;
    })
  }

  registerNewDevice() {
    this.api.registerDevice(this.participantId).subscribe(res => {
      this.notifyService.showSuccess(res.id);
      this.getDevices()
    })
  }
}
