import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FractalService} from "../../services/fractal/fractal.service";
import {Title} from "@angular/platform-browser";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../services/notifications/notification.service";
import {PreferencesService} from "../../services/preferences/preferences.service";
import {EventResultsTableComponent} from "../event-results-table/event-results-table.component";
import {analyzeEventInfo, getSortedGroups, parseResults, sortResults, sortTeams2} from "../../domain/data-utls";
import {EventDay, EventInfo, ParticipantWithDeviceDto} from "../../domain/models";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss']
})
export class EventInfoComponent {

  data: EventInfo;
  sortedGroups: string[];
  currentEvent: EventDay;
  isLoading: boolean = true;
  participants: ParticipantWithDeviceDto[] = [];

  constructor(
    private route: ActivatedRoute,
    private api: FractalService,
    private titleService: Title,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private preferencesService: PreferencesService,
    private clipboard: Clipboard,
  ) {
    titleService?.setTitle('Статистика');
  }

  ngOnInit(): void {
    this.api.getResultsNew(this.route.snapshot.params.id1, this.route.snapshot.params.id2).subscribe((res) => {
      EventResultsTableComponent.cpSettings = res.controlPointsSettings
      let newResult = parseResults(res)
      newResult = sortResults(newResult)
      this.sortedGroups = getSortedGroups(newResult)
      newResult = sortTeams2(newResult, "rogaine")
      newResult.participants.forEach(x => {
        x.teams.forEach(t => {
          t.runners.forEach(runner => {
            this.participants.push(runner)
          })
        })
      })

      this.data = analyzeEventInfo(newResult.participants); //TODO convert list to analyzed points
      this.isLoading = false
    })
    this.api.GetDay(this.route.snapshot.params.id, this.route.snapshot.params.id2).subscribe((res) => {
      this.currentEvent = res;
    })
  }

  download() {

  }

  copyAsCSV() {
    var tab = "\t"
    var crlf = "\n"
    var s = "bib" + tab + "surname" + tab + "name" + tab + "group" + crlf
    this.participants.forEach(x => {
      s += x.bib + tab + x.surname + tab + x.name + tab + x.groupName + crlf
    })
    this.clipboard.copy(s)
    this.notifyService.showSuccess("Copied to clipboard!")
  }
}
