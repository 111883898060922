import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GpsSettings} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-gps-settings',
  templateUrl: './gps-settings.component.html',
  styleUrls: ['./gps-settings.component.scss']
})
export class GpsSettingsComponent implements OnInit {
  gpsSettings: GpsSettings[];
  model: GpsSettings = new GpsSettings();
  isUpdateEventEnabled = false;

  constructor(
    private fractalService: FractalService,
    private modalService: NgbModal,
    private titleService: Title,
    private notifyService : NotificationService
  ) {
    titleService.setTitle("Настройки GPS")
  }

  ngOnInit() {
    this.getGpsSettings();
  }

  getGpsSettings() {
    this.fractalService.getGpsSettings().subscribe(res => {
      this.gpsSettings = res;
    })
  }

  showOrHideUpdateDialog() {
    this.isUpdateEventEnabled = !this.isUpdateEventEnabled
  }

  open(content) {
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.save();
      }, (reason) => {
      });
  }
  save() {
    console.log(this.model);
    this.fractalService.createGpsSettings(this.model).subscribe(res => {
      console.log(res);
    });
  }

}
