import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ControlPointsSettings,
  Format,
  Genders,
  GpsSettings,
  Group,
  PunchAlgorithm,
  SelectionalDistance
} from 'src/app/domain/models';
import {FractalService} from 'src/app/services/fractal/fractal.service';
import {NotificationService} from "../../services/notifications/notification.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-format-details',
  templateUrl: './format-details.component.html',
  styleUrls: ['./format-details.component.scss']
})
export class FormatDetailsComponent implements OnInit {
  controlTime: number = 0;

  model: Format;
  newGroupModel: Group = new Group();
  event: Format;
  groups: Group[];
  isUpdateEventEnabled = false;
  isCreateFormatEnabled = false;
  eventId: string = "";
  dayId: string = "";
  formatId: string = "";
  selectedAlgorithm: PunchAlgorithm;
  selectedGpsSettings: GpsSettings;
  punchAlgorithms: PunchAlgorithm[];
  gpsSettings: GpsSettings[];
  closeResult = '';
  distance: SelectionalDistance = new SelectionalDistance();
  selectionalControlPointsSettings: ControlPointsSettings;
  controlPointsSettings: ControlPointsSettings[];

  constructor(
    private fractalService: FractalService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private titleService: Title,
    private notifyService: NotificationService
  ) {
    titleService.setTitle("Формат")
    this.newGroupModel.genders = new Genders();
  }

  ngOnInit() {
    const id1: string = this.route.snapshot.params.id1;
    const id2: string = this.route.snapshot.params.id2;
    const id3: string = this.route.snapshot.params.id3;
    this.eventId = id1;
    this.dayId = id2;
    this.formatId = id3;
    this.getFormat(this.eventId, this.dayId, this.formatId);
    this.fractalService.getPunchAlgorithms().subscribe(p => {
      this.punchAlgorithms = p;
    })
    this.fractalService.getGpsSettings().subscribe(g => {
      this.gpsSettings = g;
    })
    this.fractalService.getGroups(this.eventId, this.dayId, this.formatId).subscribe(g => {
      this.groups = g;
    })
    this.fractalService.getControlPointsSettings().subscribe(g => {
      this.controlPointsSettings = g;
    })
  }

  getFormat(eventId: string, dayId: string, formatId: string) {
    this.fractalService.getFormat(eventId, dayId, formatId)
      .subscribe(heroes => {
          this.model = heroes
          this.selectedAlgorithm = heroes.punchAlgorithm
          this.selectedGpsSettings = heroes.gpsSettings
        }
      );
  }

  save() {
    console.log("model: " + this.model);
    this.model.punchAlgorithm = this.selectedAlgorithm;
    this.model.gpsSettings = this.selectedGpsSettings;
    this.fractalService.updateFormat(this.eventId, this.dayId, this.model).subscribe(res => {
      this.notifyService.showSuccess(res);
    });
  }

  showOrHideUpdateDialog() {
    this.isUpdateEventEnabled = !this.isUpdateEventEnabled
  }

  showOrHideCreateDialog() {
    this.isCreateFormatEnabled = !this.isCreateFormatEnabled
  }

  open(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.save();
      console.log(this.model);
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    console.log(reason);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openCreateDialog(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      console.log(this.punchAlgorithms);
      console.log(this.gpsSettings);
      this.newHero();
      console.log(this.model);
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
        `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  updateFormat() {
    console.log(this.model);
    console.log("alg: " + this.selectedAlgorithm.toString())
    console.log("gps: " + this.selectedGpsSettings.toString())
    const dayId: string = this.route.snapshot.params.id2;
    const id: string = this.route.snapshot.params.id1;
    this.model.punchAlgorithm = this.selectedAlgorithm;
    this.model.gpsSettings = this.selectedGpsSettings;
    this.fractalService.updateFormat(id, dayId, this.model).subscribe(heroes => {
        this.notifyService.showSuccess(heroes);
        this.getFormat(this.eventId, this.dayId, this.formatId);
      }
    );
  }

  newHero() {
    console.log(this.newGroupModel);
    console.log(this.newGroupModel.genders.femaleAllowed);
    console.log(this.newGroupModel.genders.maleAllowed);

    this.distance.controlTimeInSeconds = this.controlTime;
    this.distance.minCount = 0;
    this.distance.type = "selectional";
    this.distance.controlPointSettingsId = this.selectionalControlPointsSettings.id;
    const dayId: string = this.route.snapshot.params.id2;
    const id: string = this.route.snapshot.params.id1;
    const formatId: string = this.route.snapshot.params.id3;
    this.newGroupModel.distances = new Array(this.distance);
    this.fractalService.createGroup(id, dayId, formatId, this.newGroupModel).subscribe(heroes => {
        this.fractalService.getGroups(this.eventId, this.dayId, this.formatId).subscribe(g => {
          this.groups = g;
        });
        this.notifyService.showSuccess(heroes);
      }
    );
  }
}
