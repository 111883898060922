import {
  AnalyzedPunch,
  AnalyzedPunchData,
  EventInfo,
  EventResults,
  EventResultsNew,
  ExtendControlPoint,
  ParticipantInfo,
  ParticipantStatuses,
  ParticipantWithDeviceDto,
  TeamLocal
} from './models';
import {
  associateDevicePunchesToControlPoints,
  getFinishTimeInMillis,
  getStartTimeInMillis,
  NewDevice,
  scoreNew
} from './result-utils';
import {millisToTime, timeToMillisUtc} from './date-utils';
import {EventResultsTableComponent} from "../pages/event-results-table/event-results-table.component";

export function parseResults(results: EventResultsNew): EventResults {
  const newResults = new EventResults();
  newResults.controlPointsSettings = results.controlPointsSettings;
  const info: ParticipantInfo[] = [];

  results.teams.forEach((t: TeamLocal) => {
    t.groups.forEach(g => {
      if (info?.find(t => t.groupName == g) == null) {
        // TODO create group
        info?.push(new ParticipantInfo(g, t));
      } else {
        const index = info?.findIndex(t => t.groupName == g);
        info[index].teams?.push(t);
        // TODO get index and push new team into group
      }
    });
  });
  info?.forEach((g, gi) => {
    g?.teams?.forEach((t, ti) => {
      t?.runners?.forEach((r, ri) => {
        info[gi].teams[ti].runners[ri].groupName = g.groupName
        if (r.device != null) {
          let d = new NewDevice();
          d.id = r.device.id
          d.createdDate = r.device.createdDate
          d.imaginaryId = r.device.imaginaryId
          info[gi].teams[ti].runners[ri].newDevice = d
        }
      })
    })
  });
  newResults.participants = info;
  return newResults;
}

export function sortResults(results: EventResults): EventResults {
  const newResults = new EventResults();
  newResults.controlPointsSettings = results.controlPointsSettings;
  newResults.participants = results.participants.sort((a, b) => {
    if (a.groupName > b.groupName) {
      return 1;
    }
    if (a.groupName < b.groupName) {
      return -1;
    }
    return 0;
  });
  return results;
}

export function getResult(participant: ParticipantWithDeviceDto) {
  try {
    const s = score(participant.newDevice, participant.controlPointsSettingsId);
    let penalty = getPenalty(participant);
    if (penalty == '-') {
      penalty = 0;
    }
    return +s - +penalty;
  } catch (error) {
    return '-';
  }
}

function getPenalty(participant: ParticipantWithDeviceDto) {
  try {
    if (participant?.newDevice == null || participant?.controlFinishTime == '' || participant?.controlFinishTime == '-') {
      return '-';
    }
    const startTime = participant?.controlFinishTime;
    const finishTime = getFinishTimeInMillis(participant);
    if (finishTime == null || startTime == null || finishTime == -1 || startTime == '') {
      return '-';
    }
    const penalty = getPenaltyTest(startTime, finishTime.toString());
    if (penalty == null) {
      return '-';
    } else {
      return penalty;
    }
  } catch (error) {
    return '-';
  }
}

export function getPenaltyTest(startTime: string, finishTime: string) {
  try {
    const t1 = Date.parse(startTime);
    const t2 = Date.parse(finishTime);

    const diffInMillis = t2 - t1;
    const diffInSeconds = Math.floor((diffInMillis / 1000));
    if (diffInSeconds <= 0) {
      return 0;
    } else {
      const minutes = Math.floor(diffInSeconds / 60);
      if (minutes == 0) {
        if (diffInSeconds == 0) {
          return 0;
        } else {
          return 1;
        }
      } else {
        const secondsLeft = diffInSeconds - (minutes * 60);
        if (secondsLeft == 0) {
          return minutes;
        } else {
          return (minutes + 1);
        }
      }
    }
  } catch (error) {
    return 0;
  }
}

export function score(device, controlPointSettingsId: string): string {
  if (device == null) {
    return '0';
  }
  return scoreNew(device).toString();
}

export function getResultTimeByTeamNew(team: TeamLocal) {
  return Math.max.apply(Math, team.runners.map(function (o) {
    try {
      const t = getResultTimeInMilliseconds(o);
      if (isNaN(t)) {
        return 0;
      }
      return t;
    } catch (error) {
      console.log('error: ' + error);
      return 0;
    }
  }));
}

export function getResultByTeamNew(team: TeamLocal) {
  return Math.max.apply(Math, team.runners.map(function (o) {
    try {
      const r = getResult(o);
      return r;
    } catch (error) {
      console.log(error);
      return '';
    }
  }));
}

export function getResultTimeNew(participant: ParticipantWithDeviceDto): string {
  let foundFinish;
  let foundStart;
  let difference;
  try {
    foundFinish = getFinishTimeInMillisNew(participant);
    foundStart = getStartTimeInMillis(participant);
    if (foundStart == null) {
      foundStart = participant.controlStartTime;
    }
    difference = new Date(foundFinish).valueOf() - new Date(foundStart).valueOf();
    const m = millisToTime(difference);
    return m;
    // return moment.utc(new Date(difference)).utc().format("HH:mm:ss");
  } catch (error) {
    console.log(error);
    try {
      foundFinish = (participant.newDevice).punches.find(e => e.code == 240).timestamp;
      foundStart = participant.controlStartTime;
      difference = new Date(foundFinish).valueOf() - new Date(foundStart).valueOf();
      return difference.toString();
    } catch (error) {
      console.log(error);
      return '-';
    }
  }
}

export function getResultTimeInMilliseconds(participant: ParticipantWithDeviceDto): number {
  let foundFinish;
  let foundStart;
  let difference;
  try {
    foundFinish = getFinishTimeInMillisNew(participant);
    foundStart = getStartTimeInMillis(participant);
    if (foundStart == null) {
      foundStart = participant.controlStartTime;
    }
    difference = new Date(foundFinish).valueOf() - new Date(foundStart).valueOf();
    return difference;
    // return moment.utc(new Date(difference)).utc().format("HH:mm:ss");
  } catch (error) {
    console.log(error);
    try {
      foundFinish = (participant.newDevice).punches.find(e => e.code == 240).timestamp;
      foundStart = participant.controlStartTime;
      difference = new Date(foundFinish).valueOf() - new Date(foundStart).valueOf();
      return difference;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }
}

export function getFinishTimeNew(participant: ParticipantWithDeviceDto) {
  const finishPunch1 = getFinishTimeInMillis(participant);
  // var finishPunch = participant?.newDevice?.punches?.find(e => e.code == 240)
  // participant?.newDevice?.punches?.forEach(x => {
  //   //TODO add checking for first finish before last cp
  // })
  if (finishPunch1 == null) {
    return '-';
  } else {
    return finishPunch1;
  }
}

export function getFinishTimeInMillisNew(participant: ParticipantWithDeviceDto): string {
  try {
    let lastFinishPunch: ExtendControlPoint = null;
    let lastNotFinishPunch: ExtendControlPoint = null;
    let lastFinishPunchIndex: number = null;
    let lastNotFinishPunchIndex: number = null;
    const punchesAll = participant?.newDevice?.punches?.sort(function (a, b) {
      if (a.timestamp > b.timestamp) {
        return -1;
      }
      if (a.timestamp < a.timestamp) {
        return 1;
      }
      return 0;
    });

    punchesAll.filter(p => p.isDuplicate === false)?.forEach((v, i) => {
      if (v.code !== 240) {
        lastNotFinishPunch = v;
        lastNotFinishPunchIndex = i;
      }
    });

    const BreakException = {};

    try {
      punchesAll?.forEach((v, i) => {
        if (v.code === 240 && i > lastFinishPunchIndex) {
          lastFinishPunch = v;
          lastFinishPunchIndex = i;
          throw BreakException;
        }
      });
    } catch (e) {

    }

    if (lastFinishPunch == null) {
      return '';
    } else {
      return lastFinishPunch.timestamp;
    }
  } catch (error) {
    return '';
  }

}

export function getSortedGroups(results: EventResults): string[] {
  return results.participants.map(t => t.groupName).sort((one, two) => (one > two ? 1 : -1))
}

export function sortTeams2(result: EventResults, eventType: string): EventResults {
  result?.participants?.forEach((group, groupIndex) => {
    group?.teams?.forEach((team, teamIndex) => {
      team?.runners?.forEach((runner, runnerIndex) => {
        const controlPointsSettingsId = runner.controlPointsSettingsId
        const foundSettings = EventResultsTableComponent.cpSettings.find(x => {
          return x.id.toString() === controlPointsSettingsId
        })
        const newDevice = associateDevicePunchesToControlPoints(runner.device, foundSettings);
        result.participants[groupIndex].teams[teamIndex].runners[runnerIndex].newDevice = newDevice
        return newDevice
      })
    })
    group.teams = sortTeamsNew(group.teams, eventType)
  })
  return result
}

export function analyzePunches(participantsInfo: ParticipantInfo[]): AnalyzedPunchData {
  let data = new AnalyzedPunchData();
  data.controlPoint = [];

  participantsInfo.forEach(x => {
    x.teams.forEach(t => {
      t.runners.forEach(p => {
        p.newDevice?.punches?.forEach(punch => {
          console.log(punch);
          data.total+=1;
          var index = data.controlPoint?.findIndex(i => i?.minor?.toString() === punch?.code?.toString())
          if (index == -1) {
            var n = new AnalyzedPunch();
            n.minor = punch?.code?.toString();
            n.name = punch?.name?.toString();
            n.allPunches.push(punch)
            if (!punch.isDuplicate) {
              n.filteredPunches.push(punch);
            }
            if (punch.tag === "admin-add") {
              n.adminAdded.push(punch);
              data.totalAddedAdmin += 1;
            }
            if (punch.tag.includes("gps-")) {
              n.gpsAdded.push(punch)
              data.totalAddedGps += 1;
            }
            if (punch.tag.includes("qr-")) {
              n.qrAdded.push(punch)
              data.totalAddedQr += 1;
            }
            n.participants.push(p);
            data.controlPoint.push(n);
          } else {
            var n = data?.controlPoint[index]
            n.allPunches.push(punch)
            if (!punch.isDuplicate) n.filteredPunches.push(punch);
            if (punch.tag === "admin-add") {
              n.adminAdded.push(punch);
              data.totalAddedAdmin += 1;
            }
            if (punch.tag.includes("gps-")) {
              n.gpsAdded.push(punch)
              data.totalAddedGps += 1;
            }
            n.participants.push(p);
            data.controlPoint[index] = n
          }
        })
        // }
      })
    })
  })
  data.controlPoint.forEach(element => {
    element.participants = uniqByFilter(element.participants)
  });
  //TODO add sorting participant
  data.controlPoint.forEach(cp => {
    cp.participants = cp.participants.sort((a, b) => {
      var filteredA = a.newDevice.punches?.filter(x => x.code.toString() === cp.minor).filter(x => x.isDuplicate == false).sort((a, b) => {
        if (timeToMillisUtc(a.timestamp) < timeToMillisUtc(b.timestamp)) return 1;
        if (timeToMillisUtc(a.timestamp) > timeToMillisUtc(b.timestamp)) return -1;
        return 0;
      })
      a.newDevice.punches = a.newDevice.punches?.sort((a, b) => {
        if (timeToMillisUtc(a.timestamp) < timeToMillisUtc(b.timestamp)) return 1;
        if (timeToMillisUtc(a.timestamp) > timeToMillisUtc(b.timestamp)) return -1;
        return 0;
      })
      b.newDevice.punches = b.newDevice.punches?.sort((a, b) => {
        if (timeToMillisUtc(a.timestamp) < timeToMillisUtc(b.timestamp)) return 1;
        if (timeToMillisUtc(a.timestamp) > timeToMillisUtc(b.timestamp)) return -1;
        return 0;
      })
      var filteredB = b.newDevice.punches?.filter(x => x.code.toString() === cp.minor).filter(x => x.isDuplicate == false).sort((a, b) => {
        if (timeToMillisUtc(a.timestamp) < timeToMillisUtc(b.timestamp)) return 1;
        if (timeToMillisUtc(a.timestamp) > timeToMillisUtc(b.timestamp)) return -1;
        return 0;
      })
      if (timeToMillisUtc(filteredA[0].timestamp) > timeToMillisUtc(filteredB[0].timestamp)) return 1;
      if (timeToMillisUtc(filteredA[0].timestamp) < timeToMillisUtc(filteredB[0].timestamp)) return -1;
      return 0;
    })
  })
  data.controlPoint.sort((a, b) => {
    if (+a.minor > +b.minor) return 1;
    if (+a.minor < +b.minor) return -1;
    return 0;
  })
  console.log(data);
  return data;
}

export function analyzeEventInfo(participants: ParticipantInfo[]): EventInfo {
  let data = new EventInfo();
  //TODO distinct participants
  let allParticipants: ParticipantWithDeviceDto[] = []
  participants.forEach(x => {
    x.teams.forEach(t => {
      t.runners.forEach(r => {
        r.groupName = ""
        allParticipants.push(r)
      })
    })
  })
  const distincted: ParticipantWithDeviceDto[] = allParticipants.reduce((foundValues, nextEmployee) => {
    if (!foundValues.includes(nextEmployee)) {
      foundValues.push(nextEmployee);
    }
    return foundValues;
  }, []);
  console.log(distincted)
  data.participants = distincted;
  data.total = distincted.length;
  distincted.forEach(x => {
    if (x?.device?.punchData?.punches?.find(x => x.minor == 240)) {
      data.finished += 1;
      var currentYear = new Date().getFullYear()
      var birthDate = ""
      if (x?.birthDate?.indexOf(".") >= 0) {
        birthDate = x?.birthDate?.split(".")[2]
      } else {
        birthDate = x?.birthDate
      }
      var age = currentYear - +birthDate
      if (age <= 20 || age >= 55) {
        data.beneficiary += 1;
      } else {
        data.main += 1;
      }
    }
  })
  data.notStarted = data.total - data.finished
  return data;
}

function uniqByFilter<T>(array: T[]) {
  return array.filter((value, index) => array.indexOf(value) === index);
}

export function sortTeamsNew(teams: TeamLocal[], type: string) {
  return teams?.sort(function (team, ti) {
    let a = getResultByTeamNew(team)
    let b = getResultByTeamNew(ti)

    if (type === "rogaine") {
      a = getResultByTeamNew(team)
      b = getResultByTeamNew(ti)
    }
    if (type === "trail") {
      a = getResultTimeByTeamNew(team)
      b = getResultTimeByTeamNew(ti)
    }

    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  })
}


export function sortTeamsByResultNew(teams: TeamLocal[]) {
  return teams?.sort(function (team, ti) {
    let a = getResultTimeByTeamNew(team)
    let b = getResultTimeByTeamNew(ti)
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  })
}

export function getParticipantStatus(participant: ParticipantWithDeviceDto): ParticipantStatuses {
  if (participant?.device == null) return ParticipantStatuses.NOT_REGISTERED
  if (participant?.device?.punchData?.punches?.find(t => t.minor === 240) != null) return ParticipantStatuses.FINISHED
  if (participant?.device?.punchData?.punches?.length >= 1) return ParticipantStatuses.STARTED; else return ParticipantStatuses.NOT_STARTED;
}
