import * as moment from 'moment';

export const PATTERN_FULL_DATE = "yyyy-MM-DD HH:mm:ss.SSS";
export const PATTERN_UTC = "yyyy-MM-DDTHH:mm:ss";

export class DatePatterns {
  static PATTERN_HH_MM_SS: string = "HH:mm:ss";
  static PATTERN_MM_DD_YYYY: string = "MM-DD-YYYY";
  static PATTERN_DD_MM_YYYY: string = "DD-MM-YYYY";
}

export function utcToLocal(time: string, pattern: string = 'HH:mm:ss'): string {
  try {
    return moment.utc(time).local().format(pattern);
  } catch (e) {
    return null;
  }
}

export function timeToMillis(time: string): number {
  try {
    return moment.utc(time).local().milliseconds();
  } catch (e) {
    return null;
  }
}

export function timeToMillisUTC(time: string): number {
  try {
    return moment.utc(time).milliseconds();
  } catch (e) {
    return null;
  }
}

export function getDiffBetweenTimes(startTime, finishTime): string {
  const utcFinishTime = timeToMillisUtc(finishTime);
  const utcStartTime = timeToMillisUtc(startTime);
  let diffMs = utcFinishTime - utcStartTime;
  return millisToTimeNotLocal(diffMs)
}

export function timeToMillisUtc(time: string): number {
  try {
    return moment.utc(time).valueOf()
  } catch (e) {
    return null
  }
}

export function millisToTimeNotLocal(time: number, pattern: string = DatePatterns.PATTERN_HH_MM_SS): string {
  if (time == 0) return null;
  try {
    return moment.utc(time).format(pattern);
  } catch (e) {
    return null
  }
}

export function millisToTime(time: number, pattern: string = 'HH:mm:ss'): string {
  if (time <= 0) {
    return null;
  }
  try {
    return moment.utc(time).local().format(pattern);
  } catch (e) {
    return null;
  }
}

export function millisToTimeUtc(time: number, pattern: string = 'HH:mm:ss'): string {
  if (time == 0) {
    return '';
  }
  return moment.utc(time).format(pattern);
}
